import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';

const GalleryPage = () => {
  return (
    <Layout>
      <Seo title="Gallery" />
      <section className="bg-white overflow-hidden text-gray-700 pt-10 sm:pt-0">
        <div className="container px-5 py-2 mx-auto lg:py-12 lg:px-32">
          <div className="pb-2 border-b border-gray-200 mb-5">
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-blue-900 sm:text-4xl">
              Gallery
            </p>
          </div>
          <div className="flex flex-wrap -m-1 md:-m-2">
            <div className="flex flex-wrap sm:w-1/3">
              <div className="w-full p-2 md:p-2">
                <StaticImage alt="gallery" className="block object-cover object-center w-full h-full rounded-lg"
                  src="../images/gallery/campaign1.jpeg" />
              </div>
            </div>
            <div className="flex flex-wrap sm:w-1/3">
              <div className="w-full p-2 md:p-2">
                <StaticImage alt="gallery" className="block object-cover object-center w-full h-full rounded-lg"
                  src="../images/gallery/campaign2.jpeg" />
              </div>
            </div>
            <div className="flex flex-wrap sm:w-1/3">
              <div className="w-full p-2 md:p-2">
                <StaticImage alt="gallery" className="block object-cover object-center w-full h-full rounded-lg"
                  src="../images/gallery/demordaunt_02.jpeg" />
              </div>
            </div>
            <div className="flex flex-wrap sm:w-1/3">
              <div className="w-full p-2 md:p-2">
                <StaticImage alt="gallery" className="block object-cover object-center w-full h-full rounded-lg"
                  src="../images/gallery/demordaunt_03.jpeg" />
              </div>
            </div>
            <div className="flex flex-wrap sm:w-1/3">
              <div className="w-full p-2 md:p-2">
                <StaticImage alt="gallery" className="block object-cover object-center w-full h-full rounded-lg"
                  src="../images/gallery/demordaunt_06.jpeg" />
              </div>
            </div>
            <div className="flex flex-wrap sm:w-1/3">
              <div className="w-full p-2 md:p-2">
                <StaticImage alt="gallery" className="block object-cover object-center w-full h-full rounded-lg"
                  src="../images/gallery/demordaunt_07.jpeg" />
              </div>
            </div>
            <div className="flex flex-wrap sm:w-1/3">
              <div className="w-full p-2 md:p-2">
                <StaticImage alt="gallery" className="block object-cover object-center w-full h-full rounded-lg"
                  src="../images/gallery/GayannCampaign.jpeg" />
              </div>
            </div>
            <div className="flex flex-wrap sm:w-1/3">
              <div className="w-full p-2 md:p-2">
                <StaticImage alt="gallery" className="block object-cover object-center w-full h-full rounded-lg"
                  src="../images/gallery/IMG_4880.jpeg" />
              </div>
            </div>
            <div className="flex flex-wrap sm:w-1/3">
              <div className="w-full p-2 md:p-2">
                <StaticImage alt="gallery" className="block object-cover object-center w-full h-full rounded-lg"
                  src="../images/gallery/IMG_6508.jpeg" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default GalleryPage;